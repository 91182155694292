<template>
  <div ref="page_box" class="page">
    <div ref="top_box" class="header_box">
      <div style="position: relative;">
        <img src="../../assets/back_arrow.png" alt="" class="arrow" @click="goBack" v-if="!weixin">
        答题卡
      </div>
    </div>
    <div class="option_box">
      <template v-for="(item,index) in test_list">
        <span :key="index" class="option_item" :class="item.active ? 'active' : ''" @click="goReviewing(index)">{{ index+1 }}</span>
      </template>
    </div>
    <div class="btn" @click="submit">
      交卷并查看结果
    </div>
  </div>
</template>

<script>
import { submitResult } from '@/api/test'
export default {
  data() {
    return {
      test_list: [],
      len: 0,
      time: '',
      uid: sessionStorage.getItem('uid') || '',
      part_id: sessionStorage.getItem('part_id') || '',
      result_id: '',
      userid: '',
      redeem_id: '',
      answer_list: [],
      testIndex: 0,
      weixin:this.weixin
    }
  },
  created() {
    this.time = this.$route.query.time
    this.len = this.$route.query.len
    this.uid = this.$route.query.uid
    this.part_id = this.$route.query.part_id
    this.result_id = this.$route.query.result_id
    this.testIndex = this.$route.query.testIndex
    const list = JSON.parse(sessionStorage.getItem('testInfo'))
    list.forEach(element => {
      if (element.type === 1 || element.type === '1') {
        if (element.radio === '') {
          element.active = 0
        } else {
          element.active = 1
        }
      } else if (element.type === 2 || element.type === '2') {
        if (element.checkList.length > 0) {
          element.active = 1
        } else {
          element.active = 0
        }
      } else if(element.type === 3 || element.type === '3'){
        if (element.radio === '') {
          element.active = 0
        } else {
          element.active = 1
        }
      }
    })
    this.test_list = list
  },
  mounted() {
    this.setiosTop()
  },
  methods: {
    setiosTop() {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if(this.weixin){
        return false
      }
      if (isiOS) {
        if (u.indexOf(' sms_1 ') !== -1) {
          this.$refs.top_box.style.paddingTop = 40 + 'px'
          this.$refs.page_box.style.paddingTop = 88 + 'px'
        } else {
          this.$refs.top_box.style.paddingTop = 20 + 'px'
          this.$refs.page_box.style.paddingTop = 68 + 'px'
        }
        window.webkit.messageHandlers.vue_Fullscreen.postMessage('1')
      }
    },
    goReviewing(index) {
      sessionStorage.setItem("index",index)
      this.$router.push({
        path: 'reviewing',
        query: {
          uid: this.uid,
          part_id: this.part_id,
          page: index,
          time: this.time
        }
      })
      
    },
    goBack() {
      this.$router.push({
        path: 'reviewing',
        query: {
          uid: this.uid,
          part_id: this.part_id,
          page: this.testIndex,
          time: this.time
        }
      })
    },
    submit() {
      const arr = []
      this.test_list.forEach(ele => {
        if (ele.type === 1 || ele.type === '1') {
          arr.push(ele.id + '-' + ele.radio)
        } else if (ele.type === 2 || ele.type === '2') {
          arr.push(ele.id + '-' + ele.checkList.join('/'))
        } else if(ele.type === 3 || ele.type === '3'){
          arr.push(ele.id + '-' + ele.radio)
        }
      })
      var params = new URLSearchParams()
      params.append('app_type', 1)
      params.append('part_id', this.part_id)
      params.append('uid', this.uid)
      params.append('all_answer', arr.join(','))
      params.append('result_id', this.result_id)
      console.log(params)
      submitResult(params).then(res => {
        sessionStorage.removeItem('index')
        sessionStorage.removeItem('timer')
        sessionStorage.removeItem('testInfo')
        if (res.errNo === 0) {
          this.$router.push({
            path: 'testResult',
            query: {
              uid: this.uid,
              result_id: this.result_id,
              first: 1
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page{
    padding-top: 89px;
    .header_box{
      border-bottom: 1px solid #eee;
    }
}
.option_box{
    width: 100%;
    height: calc(100vh - 100px - 89px - 50px - 20px);
    overflow: scroll;
    padding: 50px 20px 20px 20px;
    box-sizing: border-box;
    // display: flex;
    // justify-content: space-around;
    // flex-wrap: wrap;
    .option_item{
        display: inline-block;
        margin: 40px 40px;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        text-align: center;
        line-height: 60px;
        background-color: #dcdcdc;
    }
    .active{
        background: #ee2e2e;
        color: #fff;
    }
}
.btn{
    width: 100%;
    height: 100px;
    text-align: center;
    background: #ee2e2e;
    color: #fff;
    position: fixed;
    bottom: 0;
    line-height: 100px;
    font-size: 16PX;
}
</style>
